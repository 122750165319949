<template>
  <div class="my-second bgcolor usdt">
    <Head :title="item.unit" :show="true" />
    <div class="usdt-detail">
        <div class="usdt-box-item" >
            <div class="item-title">
                <div class="item-title-left">
                    <img v-if="item.current_id==1011" :src="require('@/assets/imgs/usdt.png')" class="left-img" alt="">
                    <img v-if="item.current_id==1020" :src="require('@/assets/imgs/usdc.png')" class="left-img" alt="">
                    <img v-if="item.current_id==1003" :src="require('@/assets/imgs/eth.png')" class="left-img" alt="">
                    <img v-if="item.current_id==1021" :src="require('@/assets/imgs/dai.png')" class="left-img" alt="">
                    <span>{{item.unit}}</span>
                </div>
            </div>
            <div class="item-number">
                <div class="item-number-left">
                    <p class="item-key">{{$t('auction.quantity')}}</p>
                    <p  class="item-value" v-if="item.current_id==1003">{{item.usable_balance | sixNumber}}</p>
                    <p  class="item-value" v-else>{{item.usable_balance | number_format}}</p>
                </div>
                <div class="item-number-right">
                    <p class="item-key">{{$t('usdt.zhehe')}} USD</p>
                    <p class="item-value">{{item.usable_balance_usdt | number_format}}</p>
                </div>
            </div>
        </div>

        <div class="usdt-log">
            <div class="log-top">
                <span>
                    {{$t('usdt.log')}}
                </span>
                <i class="iconfont iconleibie" @click="showChoose"></i>
            </div>
            <div class="log-list"  v-if="dataList.length>0" >
                <div class="log-item" v-for="(l,i) in dataList" :key="i">
                    <div class="item-top">
                        <!-- <span v-if="l.rechargeType==31">{{$t('usdt.usdtout')}}</span>
                        <span v-if="l.rechargeType==0||l.rechargeType==2">{{$t('usdt.usdtin')}}</span>
                        <span v-if="l.rechargeType==101">{{$t('home.recharge')}} Bid Token</span>
                        <span v-if="l.rechargeType==105">{{l.type}}</span> -->
                        <span>{{l.type}}</span>
                        <i class="iconfont iconxiangqing" @click="showPopup(l,l.rechargeType)"></i>
                    </div>
                    <div class="item-box">
                        <div class="one item">
                            <p class="key">{{$t('auction.quantity')}}</p>
                            <p class="value">{{l.amount | sixNumber}}</p>
                        </div>
                        <div class="two item">
                            <p class="key">{{$t('usdt.status')}}</p>
                            <p  class="value timesize"> {{l.is_pending==0?$t('usdt.done'):$t('usdt.notdone')}}</p>
                        </div>
                         <div class="three item">
                            <p class="key">{{$t('home.time')}}</p>
                            <p  class="value timesize">{{l.time}} </p>
                        </div>
                    </div>
                </div>
                <p class="good-more" v-if="page<Math.ceil(total/pageSize)"  @click="getmoreList"> <van-icon name="arrow-down" size="20" /> </p>
                
                <!-- <p class="good-more"    @click="getmoreJoinList"> <van-icon name="arrow-down" size="20" /> </p> -->
            </div>
            <div class="log-none" v-else>
                <img :src="require('@/assets/images/xuqiu-no.png')"  alt="">
                <p>{{$t('usdt.nolog')}}</p>
            </div>
        </div>

        <div class="usdt-action">
            <div class="action-item" @click="chongbiShow=true">
                 <van-button class="top">
                     <i class="iconfont iconchongbi"></i>
                 </van-button>
                 <div class="bottom">
                     {{$t('usdt.usdtin')}}
                 </div>
            </div>
            <div class="action-item" @click="outShow=true">
                 <van-button class="top">
                     <i class="iconfont icontibi"></i>
                 </van-button>
                 <div class="bottom">
                     {{$t('usdt.usdtout')}}
                 </div>
            </div>
            <div class="action-item item-last" @click="gotousdtrecharge"> 
                 <van-button class="top">
                     <i  class="iconfont iconduihuan1"></i>
                 </van-button>
                 <div class="bottom">
                     {{$t('home.recharge')}} Bid Token
                 </div>
            </div>
        </div>
    </div>

    <van-action-sheet 
        class="usdt-sheet"
        v-model="show" 
        :actions="actions" 
        @select="onSelect" 
        :cancel-text="$t('login.cancel')"
        close-on-click-action
        @cancel="onCancel"
    />

    <van-popup class="usdt-popup" :style="{height:'16rem'}" v-model="popupShow" position="bottom"   duration="0.4" >
        <div class="usdt-popup-top">
            <i class="iconfont iconfanhui1 hidden" @click="popupShow=false"></i>
        </div>

        <div class="usdt-popup-box">
            <van-cell-group>
                <van-cell 
                    :value="  popupdata.amount + ' ' + item.unit" 
                    class="firstvalue"
                />
                 <van-cell 
                    :title="$t('usdt.type')"
                    :value="popupdata.type"
                     v-if="popupdata.type"
                />
                 <van-cell 
                 :title="$t('usdt.status')  "
                 :value="popupdata.is_pending==0?$t('usdt.done'):$t('usdt.notdone')"

                />
                <van-cell 
                    :title="$t('usdt.orderno')  "
                    :value="popupdata.orderno"
                    v-if="popupdata.orderno"
                />
                 <van-cell 
                 :title="$t('usdt.usdtout')  + $t('usdt.address')"
                    :value="popupdata.address"
                     v-if="popupdata.address"
                />
                 <van-cell 
                    :title="$t('usdt.fee')  "
                    :value="popupdata.fee"
                    v-if="popupdata.fee"
                />
                 <van-cell 
                 :title="$t('usdt.qukuai')+' ID'"
                    :value="popupdata.order_sn"
                    v-if="popupdata.order_sn"
                />
                 <van-cell 
                     :title="$t('home.time')  "
                    :value="popupdata.time"
                    v-if="popupdata.time"
                />
               
            </van-cell-group>
        </div>
    </van-popup>

    <!-- 提币  -->
    <van-popup class="usdt-popup out-box" :style="{height:'95%'}" v-model="outShow" position="bottom"   duration="0.4" >
        <div class="usdt-popup-top out-box-head">
            <i class="iconfont iconfanhui1 hidden" @click="outShow=false"></i>
        </div>
        <div class="out-box-main">
            <h1 > {{$t('usdt.usdtout')}}</h1> 
            <p class="lianname">
                {{$t('usdt.lian')}}
            </p>
            <div class="erc20">
                ERC20
            </div>

            <div class="list">
                <div class="item">
                    <p class="key">{{$t('usdt.usdtout')}}{{$t('usdt.address')}}</p>
                    <div class="value">
                        <input type="text" v-model="obj.address" class="inp-item" :placeholder="$t('usdt.pl1')">
                        <div class="right">
                            <i class="iconfont scan iconsaomiao" v-if="false">

                            </i>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <p class="key">{{$t('auction.quantity')}}</p>
                    <div class="value">
                        <input type="number"  v-model="obj.total" min="10"  class="inp-item" @blur="testMoreTen" :placeholder="$t('usdt.pl2')+min_total">
                        <div class="right">
                            <p class="txt">{{item.unit}}</p>
                            <van-button class="btn" @click="changeALLusd">{{$t('common.all')}}</van-button>
                        </div>
                    </div>
                </div>
                <div class="clearfix" >

                    <p v-if="tenShow" class="amount color-main" style="float:left;" >{{$t('usdt.pl2')+min_total}}  </p>
                    <p v-if="noShow" class="amount color-main" style="float:left;" >{{$t('usdt.not_more')}}  </p>
                    <p v-if="item.current_id==1003" class="amount" style="float:right;" >{{$t('usdt.keyong')}} {{item.usable_balance | sixNumber }} {{item.unit}} </p>
                    <p v-else class="amount" style="float:right;" >{{$t('usdt.keyong')}} {{item.usable_balance | number_format }} {{item.unit}} </p>
                </div>
                <div class="item">
                    <p class="key">{{$t('usdt.fee')}}</p>
                    <div class="value">
                        <p class="fee">{{fee | sixNumber}}</p>
                    </div>
                </div>
                <div class="shuo">
                    <p class="min">{{$t('usdt.pl2')}}: {{min_total}} {{item.unit}} (ERC20)</p>
                    <p class="anquan">{{$t('usdt.warn')}}</p>
                    
                </div>
                <!-- <p class="shadow"></p> -->
                <div class="money-tips">
                    <span class="shu">{{$t('usdt.daozhang')}} </span>
                     <span class="money" >
                          <template v-if="  (obj.total * 1)>(fee * 1) ">
                            {{  lastNum | sixNumber}}
                          </template>
                          <template v-else>
                              0
                          </template>
                          {{item.unit}}</span>
                    <!-- <span class="money">{{(obj.total  * (100-fee) / 100 ) | sixNumber }} {{item.unit}}</span> -->
                </div>

                <van-button class="tibi-btn" @click="startTixian">{{$t('usdt.usdtout')}}</van-button>
            </div>
        </div>
    </van-popup>

    <!-- 充值  -->
    <van-popup class="usdt-popup out-box" :style="{height:'95%'}" v-model="chongbiShow" position="bottom"   duration="0.4" >
        <div class="usdt-popup-top out-box-head">
            <i class="iconfont iconfanhui1 hidden" @click="chongbiShow=false"></i>
        </div>
        <div class="out-box-main">
            <h1 > {{$t('usdt.usdtin')}}</h1> 
            <p class="lianname">
                {{$t('usdt.lian')}}
            </p>
            <div class="erc20">
                ERC20
            </div>

            <div class="chongbox">
                <div class="qrcodebox">
                    <div id="qrcode" class="qrcode" ></div>
                </div>
                <van-button class="savebtn">{{$t('usdt.savecamera')}}</van-button>
                <p class="address">{{$t('usdt.usdtin')}}{{$t('usdt.address')}}</p>
                <p class="jiami">
                    {{item.address}}
                </p>
                <van-button class="copybtn" @click="onCopy">{{$t('mine.copy')}}</van-button>
            </div>

            <div class="warn-tips">
                <p class="head">{{$t('usdt.chongmsg')}}</p>
                <p class="item">
                    1.{{$t('usdt.ch1').replace(/USDT/,this.item.unit)}}
                </p>
                <p class="item">
                    2.{{$t('usdt.ch2')}}
                </p>
                <p class="item">
                    3.{{$t('usdt.ch3')}}
                </p>
                <p class="item">
                    4.{{$t('usdt.ch4')}}
                </p>
            </div>
        </div>

    </van-popup>

    <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one money-pay"  v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form label-width="140" class="pay-form" ref="pinform" @submit="payendusdt">
               
                <van-field
                v-model="pin"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="pin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[
                  { required: true, message: $t('login.required_sec_pass') },
                  { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'}
                ]"
                >

                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="launchForm">{{$t('login.confirm')}}</van-button>
            </div>
        </div>
    </van-popup>
  </div>
</template>


<script>
// 
 //交易类型  3 提币  1 充币  2 BID Token 兑换 
//   rechargeType 31 提现   0 2  充值   101 兑换 
import {Toast,Notify} from 'vant'
import {randomString} from "@/common"
import {myinfo} from "@/common"
import {passReg} from "@/common/validate"

export default {
  name:'usdt-index',
  mixins:[myinfo],
  data(){
      return {
          passPattern:passReg,
          pt3:'password',
          pin:'',
          secshow:false,  // 支付
          chongbiShow:false, // 充币
          outShow:false, // 提币
          show:false,
          flagOne:true,
          item:{},
          type:"",  //交易类型  3 提币  1 充币  2 BID Token 兑换 
          actions:[
              {name:this.$t('common.all'),id:-1,type:''},
              {name:this.$t('usdt.usdtin'),id:1,type:'1'},
              {name:this.$t('usdt.usdtout'),id:2,type:'3'},
              {name:this.$t('home.recharge') + ' Bid Token',id:3,type:'2'},
          ],
          popupShow:false,
          list:[
            //   {
            //       id:0,
            //       amount:'1500.0000000',
            //       type:"提币",
            //       status:"已完成",
            //       address:"0xsidjhihfduhia3478y7dshgidhgihfiafhjidsa78493idh",
            //       fee:"5%",
            //       quid:"0xsidjhihfduhia3478y7dshgidhgihfiafhidsa78493idh789afhiadfiyfiadyf8947358",
            //       time:"18:15:54 01/22/2021"
            //   },
            //    {
            //       id:1,
            //       amount:'3500.0000000',
            //       type:"充币",
            //       status:"已完成",
            //       quid:"0xsidjhihfduhia3478y7dshgidhgihfiafhidsa78493idh789afhiadfiyfiadyf8947358",
            //       time:"18:15:54 01/22/2021"
            //   },
            //    {
            //       id:2,
            //       amount:'2500.0000000',
            //       type:"兑换BID TOken ",
            //       status:"已完成",
            //       orderId:"OB8884732390121",
            //       time:"18:15:54 01/22/2021"
            //   }
          ],
          popupdata:{},
          page:1,
          pageSize:20,
          total:0,
          dataList:[],
          obj:{
              total:'',
          },
          fee:5,
          min_total:10,
          tenShow:false,
          noShow:false
      }
  },
  computed:{
        lastNum(){
            return this.obj.total - this.fee
        }
  },
  methods:{
        launchForm(){
            this.$refs.pinform.submit();
        },
        changePassType3(){
            this.pt3 = this.pt3 == "password"?"text":"password"
        },
        changeALLusd(){
            this.$set(this.obj,'total',this.item.usable_balance)
            console.log(this.obj)
        },
        payendusdt(){
            this.$ajax.submitCryptoWithdraw({
                pin:this.pin,  // 二级密码
                address:this.obj.address,
                current_id:this.item.current_id,
                form_token:randomString(),
                withdraw_amount:this.obj.total  , 
                receive_amount:this.obj.total   - this.fee  // 待处理
            }).then(res=>{
                
                if(res.code==200){
                    Notify({ type: 'success', message: res.msg});
                    this.outShow = false;
                    this.type = ""   //查询所有 
                    this.page = 1
                    this.getDataList();
                }
            })
        },
      startTixian(){   //提现
        if(this.obj.address&&this.obj.total){
            this.obj.total = this.obj.total * 1
            this.fee = this.fee * 1 
            this.min_total = this.min_total  * 1 
            this.item.usable_balance = this.item.usable_balance  *1 
            if(  this.obj.total>this.fee  && this.obj.total>this.min_total && this.obj.total <= this.item.usable_balance){   //小于等于 
                this.secshow = true;
            }else{
                Notify({
                    message:this.$t('usdt.max_min'),
                    color: '#ad0000',
                    background: '#ffe1e1',
                });
                // if(parseInt(this.min_total)<parseInt(this.fee)){  // 10 < 18
                //     if(parseInt(this.obj.total)<parseInt(this.min_total)){   //  8 
                //         Notify({
                //             message:this.$t('usdt.max_min'),
                //             color: '#ad0000',
                //             background: '#ffe1e1',
                //         });
                //     }else{     // 12 
                //         Notify({
                //             message:this.$t('wallet.not_balance'),
                //             color: '#ad0000',
                //             background: '#ffe1e1',
                //         });
                //     }
                // }else{  //  18  10  
                //     if(parseInt(this.obj.total)<parseInt(this.fee)){   //  8 
                //         Notify({
                //             message:this.$t('wallet.not_balance'),
                //             color: '#ad0000',
                //             background: '#ffe1e1',
                //         });
                //     }else{     // 12 
                //         Notify({
                //             message:this.$t('usdt.max_min'),
                //             color: '#ad0000',
                //             background: '#ffe1e1',
                //         });
                //     }
                // }
            }
        }else{
            Notify({
                message:this.$t('usdt.please_enter'),
                color: '#ad0000',
                background: '#ffe1e1',
            });
        }
      },
      testMoreTen(){
          //   this.fee 
          if((this.fee * 1)>this.min_total * 1){
                if(this.obj.total * 1<this.min_total * 1){
                    this.tenShow = true;
                     this.noShow = false;
                }else if(this.obj.total * 1 <(this.fee * 1)){
                    this.noShow = true;
                    this.tenShow = false;
                }else{
                    this.tenShow = false;
                     this.noShow = false;
                }
          }else{
            //   fee 10  
            //   min 18 
              if(this.obj.total * 1<(this.fee　* 1)){
                    this.tenShow = false;
                     this.noShow = true;
                }else if(this.obj.total * 1 <(this.min_total * 1)){
                    this.noShow = false;
                    this.tenShow = true;
                }else{
                    this.tenShow = false;
                     this.noShow = false;
                }
          }
      },
      gotousdtrecharge(){
          this.$router.push({
              name:"usdt-recharge"
          })
      },
      showPopup(item){
          this.popupShow =  true;
        //   this.popupdata = this.list[index];
        var obj = {}
        if(item.rechargeType==31){
            obj.fee = this.fee;   // 待处理
            obj.time  =item.time;
            obj.address = item.address;
            obj.is_pending = item.is_pending
            obj.type  = item.type 
            obj.amount = item.amount
            obj.order_sn = item.order_sn;
            obj.rechargeType = obj.rechargeType
            obj.is_send = item.is_send==1?"-":"+"
        }
        if(item.rechargeType==0 || item.rechargeType== 2 ){
           
            obj.time  =item.time;
            obj.is_pending = item.is_pending;
            obj.type  = item.type;
            // obj.address = item.address;
            obj.amount = item.amount
            obj.order_sn = item.order_sn;
            obj.rechargeType = obj.rechargeType 
            obj.is_send = item.is_send==1?"-":"+"
        }
        if(item.rechargeType==101){
            obj.time  =item.time;
            obj.is_pending = item.is_pending;
            obj.type  = item.type;
            obj.amount = item.amount
            obj.orderno = item.order_sn;
            obj.rechargeType = obj.rechargeType 
            obj.is_send = item.is_send==1?"-":"+"
        }

        if(item.rechargeType==105){
            obj.time  =item.time;
            obj.is_pending = item.is_pending;
            obj.type  = item.type;
            obj.amount = item.amount
            obj.orderno = item.order_sn;
            obj.rechargeType = obj.rechargeType 
            obj.is_send = item.is_send==1?"-":"+"
        }
        
        this.popupdata = {...obj}
      },
      showChoose(){
          this.show = true;
      },
      getmoreList(){
            this.$ajax.cryptoWalletRecord({
                current_id:this.item.current_id,
                type:this.type,
                page:this.page,
                pageSize:this.pageSize
            }).then(res=>{
                if(res.code==200){
                    this.total = res.data.total;
                    this.dataList = this.dataList.concat(res.data.data);
                    this.page +=1;
                }
            })
      },
      onSelect(value){
          this.type = value.type;

          // 第一页
          this.page = 1;
          this.$ajax.cryptoWalletRecord({
            current_id:this.item.current_id,
            type:this.type,
            page:this.page,
            pageSize:this.pageSize
        }).then(res=>{
            if(res.code==200){
                this.total = res.data.total;
                this.dataList = res.data.data;
            }
        })
      },
      onCancel(){
          this.show = false;
      },
      onCopy() {
            // /register?_t=1611124290793
            var link = this.item.address;
            var flag = this.copyText(link);
            Toast.success(this.$t('news.copysuccess'))
        },
        copyText(shareLink) {
          var _input = document.createElement("input");   // 直接构建input
          _input.value = shareLink;  // 设置内容
          document.body.appendChild(_input);    // 添加临时实例
          _input.select();   // 选择实例内容
          document.execCommand("Copy");   // 执行复制
          document.body.removeChild(_input); // 删除临时实例
      },
      getDataList(){
        this.$ajax.cryptoWalletRecord({
            current_id:this.item.current_id,
            type:this.type,
            page:this.page,
            pageSize:this.pageSize
        }).then(res=>{
            if(res.code==200){
                this.total = res.data.total;
                this.dataList = res.data.data
                // this.dataList = [...res.data.data,...res.data.data]   //测试 
                this.page++;
            }
        })
    }
  },
  watch:{
      chongbiShow(v){
          if(v&&this.flagOne){
            setTimeout(()=>{
                var qrcode = new QRCode('qrcode', {
                    width: 113,
                    height: 113,
                    colorDark : '#000000',
                    colorLight : '#ffffff',
                    correctLevel : QRCode.CorrectLevel.H,
                });

            
                var link = this.item.address
                qrcode.makeCode(link)
                this.flagOne = false;
            },100)
        }
      }
  },
 mounted(){
        const item = JSON.parse(this.$route.query.item)
        this.item = item;
        this.page = 1;
        this.getDataList()

        

        var unit =  item.unit.toLowerCase();
        // console.log(item)
        // console.log(unit)
        const dashboard = JSON.parse(localStorage.getItem('dashboard'))
        if(dashboard){
            // this.fee = dashboard.withdraw_fee_ratio * 100;
            this.fee = dashboard.transferRatio[unit+'_withdraw_ratio'] ;
            // this.min_total = this.item.current_id == 1003 ? dashboard.min_eth_withdraw_amount :  dashboard.min_usd_withdraw_amount;
            this.min_total =  (dashboard.transferRatio[unit+'_min']*1)
        }
    }
}
</script>